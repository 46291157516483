.close-button {
  position: sticky;
  top: 0;
  right: 12.5px;
  border: none;
  background: none;
  opacity: 0.4;
  font-size: 25px;
  padding: 0;
  margin: 0;
  margin-left: calc(100% - 25px);
  cursor: pointer;

  &:hover, active {
    opacity: 1;
  }
}

.modal-container {
  // this is cuz of close-button sticky behavior
  margin-top: -50px;
}

.modal-title {
  margin-top: 12px;
  user-select: none;
}


.contacts-list {
  font-size: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 180px;
  margin: auto;

  & svg {
    filter: drop-shadow(2px 2.5px 0.5px rgba(0,0,0,0.35));
    margin: 0 5px;
  }

  & a {
    transition: all 0.1s ease-in-out;
    transform-origin: bottom right;
  }

  & a:active {
    transform: scale(0.96);
  }

  & .twitter {
    color: #1DA1F2;
  }

  & .linkedin {
    color: #2867B2;
  }

  & .insta svg * {
    fill: url(#rg);
  }

  & .github {
    color: rgb(26, 23, 23);
  }

  & .email {
    color: #EA4335;
  }
}

.credits-container {
  margin-right: 50px;
}
