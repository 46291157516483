$left-color: #E5E5EA;
$right-color: #b0db8f;

html, body {
  background-color: black;
}

@mixin tail {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  margin-left: -4px;
  margin-bottom: -8px;
}

@mixin shared-msg {
  position: relative;
  max-width: 270px;
  border-radius: .7em;
  padding: 10px 15px;
  margin: 2.5px 0;
}

.chat {
  margin: 15px 20px;
  padding: 20px 10px;
  width: 325px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  user-select: none;
}

.chat-msg {
  @include shared-msg;
  box-shadow: 1px 1px 1px 0px #c1b1b1;
  // todo: still doesn't allow for 'empty' lines, hmmm..
  white-space: pre-wrap;
  
  &.right {
    margin: 10px 0;
    background: $right-color;
    transform-origin: top right;
    align-self: flex-end;

    &.tail:after {
      @include tail;

      border-top-color: $right-color;
      right: 10px;
      border-right: 0;
    }
  }

  &.left {
    background: $left-color;
    transform-origin: top left;
    align-self: flex-start;

    &.tail:after {
      @include tail;

      border-top-color: $left-color;
      left: 15px;
      border-left: 0;
    }
  }
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 33px;
  padding-top: 10px;
  transform-origin: top;
}

.chat-input {
  position: relative;
  border-radius: .7em;
  padding: 10px 30px 0 15px;
  width: 75%;
  box-shadow: 1.5px 1.5px 2px 0px #c1b1b1;

	background: $left-color;
  
  & textarea {
    border: none;
    background: transparent;
    resize: none;
    outline: none;
    width: 100%;
  }

  & .chat-submit {
    position: absolute;
    right: 0;
    bottom: 2px;
    transition: all 0.1s ease-out;
  
    border: none;
    background: transparent;
    
    color: #65854c;
  
    cursor: pointer;
    outline: none;
  
    &:active {
      transform: scale(0.9);
    }
  }
}

.chat-cancel {
  cursor: pointer;
  transition: all 0.1s ease-out;

  border: none;
  background: $left-color;
  
  color: #a03f47;

  cursor: pointer;
  outline: none;

  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-left: 0;
  padding: 2px 0;
  font-size: 11px;

  &:active {
    transform: scale(0.9);
  }
}

.chat-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.chat-option {
  @include shared-msg;
  border-radius: 0.5em;
  border: none;
  box-shadow: 1.5px 1.5px 2px 0px #c1b1b1;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  transition: all 0.1s ease-out;

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  background: $right-color;

  &:hover {
    background: #92cd65;
  }

  &:active {
    transform: scale(0.95);
  }
}

a.chat-option {
  text-decoration: none;
  color: inherit;
  text-shadow: none;;
}
