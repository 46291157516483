.App {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  width: 100vw;
}

html {
  font-family: 'Open Sans', sans-serif;
}

button {
  font-size: inherit;
}

input, textarea {
  font-size: inherit;
  font-family: inherit;
}
