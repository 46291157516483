.books-list h1,
.book {
  cursor: pointer;
  display: inline-block;
}

.book-container {
  padding-bottom: 8px;
}

.bookshelf-chat {
  position: sticky;
  right: 0;
  top: 20px;
  margin-left: calc(100% - 350px);
  height: 350px;

  & .chat {
    margin: 0 20px;
  }
}

.books-list {
  // this is cuz of chat sticky behavior
  margin-top: -350px;
}
